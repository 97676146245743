<template>
  <div class="range-page" v-loading="loading">
    <div class="range-page-title">
      <totalCom :text="'共查询到数据' + total + '条'" />
    </div>
    <div
      class="page-box"
      v-infinite-scroll="getwebSite"
      :infinite-scroll-disabled="scollDisabled"
      :infinite-scroll-distance="5"
    >
      <div class="range-page-child">
        <div class="range-item" v-for="(item, i) in list" :key="i">
          <div class="range-item-img">
            <img
              :src="item.ico ? item.ico : require('@/assets/imgs/i12.png')"
              alt=""
              :onerror="noPic"
            />
          </div>
          <div class="range-item-info">
            <div class="r-left">{{ item.remark }}</div>
            <div class="r-right">
              <span>间隔时间：{{ item.intervalTime }}小时</span>
              <span v-if="item.state == 1" class="r-type-box-1">
                <span class="r-type"></span>
                正常
              </span>
              <span v-else class="r-type-box-2">
                <span class="r-type"></span>
                结束
              </span>
            </div>
          </div>
          <div class="range-website">
            网址：
            <a :href="item.source" target="_blank">{{ item.source }}</a>
          </div>
        </div>
      </div>
      <li v-if="nomoreData && list.length > 0">加载中...</li>
      <li v-else-if="!nomoreData && list.length > 0">没有更多了...</li>
    </div>

    <!-- <pagination
      :total="total"
      :page-size="limit"
      :currentPage="current"
      :pagerCount="5"
      background
      :sizesArr="[16, 40, 100, 200]"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    /> -->
  </div>
</template>

<script>
import { webSite } from '@/api/rang.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      // 当前页
      current: 1,
      // 每页记录数
      limit: 40,
      // 总记录数
      total: 0,
      list: [],
      loading: false,
      scollDisabled: false,
      nomoreData: true
    }
  },
  created() {
    // this.getwebSite()
  },
  methods: {
    getwebSite() {
      this.loading = true
      this.scollDisabled = true
      webSite(this.current, this.limit).then(res => {
        this.total = res.all
        this.loading = false
        if (res.items.length > 0) {
          this.current++
          this.list = this.list.concat(res.items)
          this.scollDisabled = false
          if (res.items.length < this.limit) {
            this.nomoreData = false
          }
        } else {
          this.nomoreData = false
        }
      })
    },
    currentChange(val) {
      this.current = val
      this.getwebSite()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getwebSite()
    }
  }
}
</script>

<style lang="scss" scoped>
.range-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 20px;
  padding-top: 10px;
  overflow: hidden;
  .range-page-title {
    background: white;
  }
  .page-box {
    width: calc(100% + 20px);
    height: calc(100% - 30px);
    overflow: auto;
    overflow-y: auto;
    li {
      text-align: center;
      font-size: 12px;
      color: #9c9797;
      list-style: none;
    }
  }
  .range-page-child {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .range-item {
      width: calc(100% / 4 - 15px);
      height: 182px;
      margin-right: 15px;
      margin-bottom: 15px;
      overflow: hidden;
      padding: 10px;
      background: white;
      box-shadow: 0px 3px 5px 0px #cbcaca;
      .range-item-img {
        width: 100%;
        height: 102px;
        position: relative;

        img {
          position: absolute;
          width: auto;
          max-width: 90%;
          height: auto;
          max-height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .range-item-info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-top: 5px;
        .r-left {
          align-self: center;
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }
        .r-right {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
          align-self: center;
          .r-type-box-1 {
            margin-left: 20px;
            .r-type {
              display: inline-block;
              width: 20px;
              height: 15px;
              background: linear-gradient(0deg, #0c99d3, #26c4d9);
              border: 0px solid #000000;
              border-radius: 3px;
              vertical-align: middle;
              margin-right: 3px;
            }
          }
          .r-type-box-2 {
            margin-left: 20px;
            .r-type {
              display: inline-block;
              width: 20px;
              height: 15px;
              background: linear-gradient(0deg, #b6359c, #ef0a6a);
              border: 0px solid #000000;
              border-radius: 3px;
              vertical-align: middle;
              margin-right: 3px;
            }
          }
        }
        border-bottom: 1px solid #e7e7e7;
      }
      .range-website {
        font-size: 14px;
        padding: 8px 0 0 0;
        font-weight: 300;
        color: #1a1a1b;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
